<template>
  <b-card class="sticky bar">
    <slot name="inner"> </slot>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
};
</script>
<style lang="scss" scoped>
.bar {
  position: sticky;     
  top: 100px;
  width: 100%;
}
</style>