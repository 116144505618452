<template>
  <div>
    <page-head-bar>
      <template #inner>
        <div class="d-flex justify-content-end">
            <router-link :to="{name: 'economics.tenders.create'}" class="btn btn-dark">Create new</router-link>
        </div>
      </template>
    </page-head-bar>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import PageHeadBar from "@/components/layout/PageHeadBar.vue";
export default {
  components: {
    PageHeadBar,
    BButton,
  },
};
</script>